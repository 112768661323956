<template>
    <el-dialog v-model="outerVisible" title="修改个人信息" @close="closeAddShop" width="30%" :close-on-click-modal="false">
        <template #default>
            <el-dialog v-model="innerVisible" width="30%" :title="showInfo" append-to-body />
        </template>
        <template #footer>
            <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm"
                :size="formSize" status-icon>
                <el-form-item label="头像" prop="avatar">
                    <el-upload ref="upload" class="avatar-uploader"
                        action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" :show-file-list="false"
                        :on-change="handleFileChange" :auto-upload="false">
                        <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"/> -->
                        <el-avatar v-if="imageUrl" :size="150" :src="imageUrl" />
                        <el-icon v-else class="avatar-uploader-icon">
                            <Plus />
                        </el-icon>
                    </el-upload>
                </el-form-item>
                <el-form-item label="账号:" prop="adminnum">
                    <el-text tag="b">{{ruleForm.adminnum}}</el-text>
                    <!-- <el-input v-model="ruleForm.adminnum" style="width: 180px" /> -->
                </el-form-item>
                <el-form-item label="名称:" prop="adminname">
                    <el-input v-model="ruleForm.adminname" style="width: 180px" />
                </el-form-item>
                <el-form-item label="权限" prop="roleName">
                    <el-text tag="b">{{ruleForm.roleName}}</el-text>
                    <!-- <el-input v-model="ruleForm.roleName" style="width: 180px" /> -->
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm(ruleFormRef)" :loading="addUserLoading">更新信息
                    </el-button>
                </el-form-item>
            </el-form>
        </template>
    </el-dialog>
</template>
  
<script lang="ts" setup>
import { ref, toRefs, toRef, reactive, watch, computed, PropType, onMounted } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import type { UploadInstance, UploadFile, UploadFiles } from 'element-plus';
import { Plus } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
import { update } from '@/api/user'

const formSize = ref('default')
const ruleFormRef = ref<FormInstance>()

interface shopInfoType {
    id:number,
    avatar: String,
    adminnum: number,
    adminname: String,
    roleName: String
}
const props = defineProps({
    updateShow: Boolean,
    shopInfo: Object as PropType<shopInfoType>
    // shopInfo:Object
})

const ruleForm = reactive({
    id:'',
    avatar:'',
    adminnum: '',
    adminname: '',
    roleName: ''
})

watch(
    () => props.shopInfo,
    (newVal: any, oldVal: any) => {   
        ruleForm.id = newVal.id; 
        imageUrl.value = newVal.avatar;
        ruleForm.adminnum = newVal.adminnum;
        ruleForm.adminname = newVal.adminname;
        ruleForm.roleName = newVal.roleName;
    },
)

const files: any = ref()
const imageUrl = ref('')

const upload = ref<UploadInstance>()

const handleFileChange = (uploadFile: UploadFile, UploadFiles: UploadFiles) => {
    const type = ['image/png'];
    const extFileName: string = uploadFile.raw?.type as string;
    const extFileSize: Number = uploadFile.raw?.size as Number;
    console.log(uploadFile.raw)
    if (type.indexOf(extFileName) === -1) {
        ElMessage({
            type: 'warning',
            message: '文件类型必须是png图片文件'
        })
        upload.value!.clearFiles()
        return false;
    } else if (extFileSize > 500000) {
        ElMessage({
            type: 'warning',
            message: '文件大小不能大于500K'
        })
        upload.value!.clearFiles()
        return false;
    }
    imageUrl.value = window.URL.createObjectURL(uploadFile.raw as Blob)//将选好的照片显示出来
    files.value = uploadFile.raw;
    upload.value!.clearFiles()
}

const showInfo = ref('')

const rules = reactive<FormRules>({
    title: [
        { required: true, message: '请输入标题', trigger: 'blur' },
        { min: 2, max: 100, message: '用户名长度在2到10个字符', trigger: 'blur' },
    ],
    content: [
        { required: true, message: '请输入内容', trigger: 'blur' },
        { min: 2, max: 100, trigger: 'blur' },
    ],

})

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return
    await formEl.validate((valid, fields) => {
        if (valid) {
            // console.log('submit!')
            submitEditShop();
        } else {
            console.log('error submit!', fields)
        }
    })
}
const emit = defineEmits(['closeUpdateShop', 'updateShopComplete'])

const addUserLoading = ref(false);

const submitEditShop = async () => {
    addUserLoading.value = true;
    try {

        console.log("ruleForm", ruleForm)
        let formData = new FormData();
        formData.append('file',files.value)
        formData.append('id',ruleForm.id)
        formData.append('adminname',ruleForm.adminname)
        const updateResult: any = await update(formData);
        addUserLoading.value = false;
        if (updateResult.success) {
            console.log("updateResult", updateResult)
            emit('updateShopComplete');
            outerVisible.value = false;
            ElMessage({
                    type: 'info',
                    message: updateResult.message,
                })
        } else {
            showInfoBox(updateResult.message)
        }
    } catch (error) {
        console.log(error);
        addUserLoading.value = false;
    }
}

const showInfoBox = (info: string) => {
    showInfo.value = info;
    innerVisible.value = true;
}

const outerVisible = computed({
    get() {
        return props.updateShow;
    },
    set(v) {
        emit('closeUpdateShop', "close Update shop")
    },
});

const innerVisible = ref(false)

const closeAddShop = () => {
    // console.log("close add shop")
    outerVisible.value = false;
}
</script>
<style scoped>
.dialog-footer button:first-child {
    margin-right: 10px;
}
</style>