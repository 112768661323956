// import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/components/index.vue'
import error404 from '@/views/error/404.vue'
import { shallowRef } from 'vue'

export const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    component: () => import('@/views/login/login.vue'),
    meta: { hidden: true }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/welcome/index.vue'),
        meta: {
          alwaysShow: false
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    component: error404,
    meta: { hidden: true }
  },
]


export const asyncRoutes: Array<RouteRecordRaw> = [
  {
    path: '/announcements',
    component: shallowRef(Layout),
    redirect: '/announcements/manage',
    meta: {
      title: '公告栏管理',
      icon: 'User',
      roleLevels:[1],
      alwaysShow: true,
      haveSubs: false
    },
    children: [
      {
        path: 'manage',
        component: () => import('@/views/announcement-manage/index.vue'),
        meta: {
          title: '公告栏管理',
          icon: 'User',
          roleLevels:[1],
          alwaysShow: true
        }
      }
    ]
  },
  {
    path: '/users',
    component: shallowRef(Layout),
    redirect: '/users/manage',
    meta: {
      title: '商家管理',
      icon: 'User',
      roleLevels:[1],
      alwaysShow: true,
      haveSubs: false
    },
    children: [
      {
        path: 'manage',
        component: () => import('@/views/user-manage/index.vue'),
        meta: {
          title: '商家管理',
          icon: 'User',
          roleLevels:[1],
          alwaysShow: true
        }
      }
    ]
  },
  {
    path: '/weixinusers',
    component: shallowRef(Layout),
    redirect: '/weixinusers/manage',
    meta: {
      title: '微信用户管理',
      icon: 'User',
      roleLevels:[1],
      alwaysShow: true,
      haveSubs: false
    },
    children: [
      {
        path: 'manage',
        component: () => import('@/views/weixinuser-manage/index.vue'),
        meta: {
          title: '微信用户管理',
          icon: 'User',
          roleLevels:[1],
          alwaysShow: true
        }
      }
    ]
  },
  {
    path:'/shop',
    component:shallowRef(Layout),
    redirect:'/shop/manage',
    meta:{
      title:'销售信息管理',
      icon:'GoodsFilled',
      roleLevels:[1,2],
      alwaysShow:true,
      haveSubs:true
    },
    children:[
      {
        path:'shopManage',
        component:()=>import('@/views/shop-manage/index.vue'),
        meta:{
          title:'商品管理',
          icon:'User',
          roleLevels:[1,2],
          alwaysShow:true
        }
      },
      {
        path:'shopTypeManage',
        component:()=>import('@/views/shop-type-manage/index.vue'),
        meta:{
          title:'商品类别管理',
          icon:'Goods',
          roleLevels:[1],
          alwaysShow:true
        }
      }
    ]
  },
  {
    path: '/transfer',
    component: shallowRef(Layout),
    redirect: '/transfer/manage',
    meta: {
      title: '转让管理',
      icon: 'Sort',
      roleLevels:[1,2],
      alwaysShow: true,
      haveSubs: true
    },
    children: [
      {
        path: 'manage1',
        component: () => import('@/views/transfer-manage/transfer.vue'),
        meta: {
          title: '宠物转让管理',
          icon: 'Sort',
          roleLevels:[1,2],
          alwaysShow: true
        }
      },
       {
        path: 'manage2',
        component: () => import('@/views/transfer-manage/idle.vue'),
        meta: {
          title: '闲置转让管理',
          icon: 'Sort',
          roleLevels:[1,2],
          alwaysShow: true
        }
      }
    ]
  },
  {
    path: '/order',
    component: shallowRef(Layout),
    redirect: '/order/manage',
    meta: {
      title: '订单管理',
      icon: 'Edit',
      roleLevels:[1,2],
      alwaysShow: true,
      haveSubs: true
    },
    children: [
      {
        path: 'manage1',
        component: () => import('@/views/order-manage/collocation.vue'),
        meta: {
          title: '托管订单管理',
          icon: 'Edit',
          roleLevels:[1,2],
          alwaysShow: true
        }
      },
      {
        path: 'manage2',
        component: () => import('@/views/order-manage/toiletry.vue'),
        meta: {
          title: '洗护订单管理',
          icon: 'Edit',
          roleLevels:[1,2],
          alwaysShow: true
        }
      },
      {
        path: 'manage3',
        component: () => import('@/views/order-manage/things.vue'),
        meta: {
          title: '用品订单管理',
          icon: 'Edit',
          roleLevels:[1,2],
          alwaysShow: true
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
    meta: { alwaysShow: false }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes:constantRoutes
})


export function resetRouter() {
  console.log("重置路由")
  let newRoutes: RouteRecordRaw[] = []
  const newRouter = createRouter({ history: createWebHashHistory(), routes: newRoutes });
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
