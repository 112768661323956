import router from './router';
import {RouteLocationNormalized} from 'vue-router';
import { ElMessage } from 'element-plus';
import {adminAuthStore,GetAdminInfo} from './store/modules/admin'; 
import {adminpermissionStore} from './store/modules/permission'; 

import NProgress from "nprogress";
import 'nprogress/nprogress.css';
NProgress.configure({ showSpinner: false })

const whiteList = ['/login']


router.beforeEach(async(to:RouteLocationNormalized,_:RouteLocationNormalized,next:any)=>{
    const adminStore = adminAuthStore()
    // console.log(adminStore.token);
    
    if(adminStore.token){
        if(to.path === '/login'){
            // console.log("token拿到了")
            next({path:'/'})
            NProgress.done()
        }else{ 
            // console.log(1111); 
            //所以在刷新页面时需要判断一下router对象是否执行了添加新路由的操作
            const adminpermission = adminpermissionStore()
            if(adminpermission.addRouteState){
                next();
            }else{
                // console.log(3333);
                try {     
                    await GetAdminInfo()
                    const roleLevel = adminStore.roleLevel                
                    if(roleLevel){
                      adminpermission.generateRoutes(roleLevel)
                      adminpermission.dynamicRoutes.forEach(route => {
                      router.addRoute(route)   
                      })
                    }
                } catch (error) {
                    // console.log("路由信息加载失败！！！");
                    ElMessage.error("路由信息加载失败！！！")
                    next('/login')
                    NProgress.done()
                }
                adminpermission.SetAddRoutesState(true);
                next({ ...to, replace: true })
            } 
                NProgress.done() 
        }
    }else{
        // console.log("token没拿到")   
        // console.log(to.path);
        // console.log(whiteList.indexOf(to.path));
        if(whiteList.indexOf(to.path) !== -1){
            next()
        }else{
            next({path:'/login'})
        }
        NProgress.done()
    }
})
