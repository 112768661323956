import { defineStore } from "pinia";
import { login, getAdminByToken } from '@/api/admin'
import { getToken, setToken, removeToken } from '@/utils/cookies'
import { resetRouter } from '@/router'
import {adminpermissionStore} from './permission'; 

export const adminAuthStore = defineStore("admin", {
  state: () => ({
    token: getToken() || '',
    id:'',
    avatar:'',
    adminname:'',
    adminnum:'',
    roleName:'',
    roleLevel:0
  }),
  getters: {

  },
  actions: {

  },
  persist: {
    enabled: true
  }

});

export const Login = async (adminnum: string, password: any) => {
  // console.log(adminnum,password);
  const result = await login({ adminnum, password })
  var Pic = result.data.data.admin.src
  let blob = new Blob([new Uint8Array(Pic.data)], { type: 'image/png' });
  result.data.data.admin.src = URL.createObjectURL(blob);
  console.log(result.data);
  if(result.data.success===true){
    const authStore = adminAuthStore()
    authStore.id = result.data.data.admin.id
    authStore.token = result.data.data.token
    authStore.avatar = result.data.data.admin.src
    authStore.adminname = result.data.data.admin.adminname
    authStore.adminnum = result.data.data.admin.adminnum
    authStore.roleName = result.data.data.admin.role.name
    authStore.roleLevel = result.data.data.admin.role.id
    setToken(result.data.token)
  }
  return result.data.success
  // adminAuthStore().setToken(result.data.token);
  // return {msg:'账号或密码错误'}

}


export const GetAdminInfo = async () => {
  const authStore = adminAuthStore()
  if (authStore.token === '') {
    throw Error('获取用户信息错误：未持有token')
  }
  console.log(authStore.adminnum);
  const result = await getAdminByToken(authStore.adminnum)
  // console.log(result.data)
  var Pic = result.data.admininfo.src
  let blob = new Blob([new Uint8Array(Pic.data)], { type: 'image/png' });
  result.data.admininfo.src = URL.createObjectURL(blob);
  console.log(result.data.admininfo);
  authStore.id = result.data.admininfo.id
  authStore.avatar = result.data.admininfo.src
  authStore.adminname = result.data.admininfo.adminname
  authStore.adminnum = result.data.admininfo.adminnum
  authStore.roleName = result.data.admininfo.role.name
  authStore.roleLevel = result.data.admininfo.role.id
}


export const ResetToken = () => {
  removeToken()
  const authStore = adminAuthStore()
  authStore.token = ''
  authStore.id = '',
  authStore.avatar = '',
  authStore.adminname = ''
  authStore.adminnum = ''
  authStore.roleName = '',
  authStore.roleLevel= 0
}

export const Logout = async () => {
  const authStore = adminAuthStore()
  if (authStore.token === '') {
    throw Error('LogOut: token is undefined!')
  }
  removeToken()
  resetRouter()
  authStore.token = ''
  authStore.id = '',
  authStore.avatar = '',
  authStore.adminname = ''
  authStore.adminnum = ''
  authStore.roleName = '',
  authStore.roleLevel= 0
  const adminpermission = adminpermissionStore()
  adminpermission.SetAddRoutesState(false)
}