import request from '@/utils/axios'

export const getAllUsers = () =>
  request({
    url: '/admin/getAllUsers',
    method: 'get',
})


export const addadmin = (data:any) =>
  request({
    url: '/admin/addadmin',
    method: 'post',
    data
})


export const update = (data: any) =>
request({
  url: '/admin/updateadmin',
  method: 'put',
  data
})


export const deleteadmin = (id: number) =>
request({ 
   url: `/admin/deleteadmin/${id}`,
   method: 'post',
})



