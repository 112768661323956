import { defineStore } from "pinia";
import { RouteRecordRaw } from 'vue-router'
import { asyncRoutes } from '@/router'


// export const filterAsyncRoutes = (routes: RouteRecordRaw[]) => {
//     const res: RouteRecordRaw[] = []
//     for (const route of routes) {
//         // 判断 route 是否符合你的条件，如果是，就添加到 res 数组中
//         if (route.children) {
//             // 这里只是一个示例，你可以根据你自己的逻辑来判断
//             route.children = filterAsyncRoutes(route.children)
//         }
//         res.push(route)
//     }
//     return res
// }


const hasPermission = (roleLevel:number, route: RouteRecordRaw) => {
    if (route.meta && route.meta.roleLevels) {
        const routeRoleLevels:any = route.meta.roleLevels;
        if(routeRoleLevels.indexOf(roleLevel) != -1){
            return true;
        }
        return false;
    } else {
      return true
    }
  }



export const filterAsyncRoutes = (routes: RouteRecordRaw[],roleLevel:number) => {
    const res: RouteRecordRaw[] = []
    routes.forEach(route => {
      const r = { ...route }
       // 判断 route 是否符合你的条件，如果是，就添加到 res 数组中
       if (hasPermission(roleLevel, r)) {
        if (r.children) {
          r.children = filterAsyncRoutes(r.children, roleLevel)
        }
        res.push(r)
    }
    })
    // console.log("res",res);
    
    return res
}


export const adminpermissionStore = defineStore("permission", {
    state: () => {
        return {
            dynamicRoutes: [] as RouteRecordRaw[],
            addRouteState: false
        }
    },
    getters: {

    },
    actions: {
        SET_ROUTES(routes: RouteRecordRaw[]){
            this.dynamicRoutes = routes
        },
        // 生成动态路由
        generateRoutes(roleLevel: number) {
            let accessedRoutes: RouteRecordRaw[]
            accessedRoutes = filterAsyncRoutes(asyncRoutes, roleLevel)
            this.SET_ROUTES(accessedRoutes)
        },
        AddRouteState(addState: boolean) {
            this.addRouteState = addState
        },
        SetAddRoutesState(stateValue: boolean){
            this.AddRouteState(stateValue)
        }

    },
});