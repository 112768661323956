import axios from 'axios'
import { adminAuthStore } from '@/store/modules/admin'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import { ResetToken } from '@/store/modules/admin'

let loading: any;
const startLoading = () => {
  interface Options {
    lock: boolean;
    text: string;
    background: string;
  }
  const options: Options = {
    lock: true,
    text: '加载中',
    background: 'rgba(0,0,0,0,7)'
  }
  loading = ElLoading.service(options)
}
const endLoading = () => {
  loading.close();
}


const service = axios.create({
  // url = base url + request url
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: 'http://20.24.227.43:7001/api',
  // baseURL: 'http://127.0.0.1:7001/api',
  timeout: 5000
})

//请求拦截器
service.interceptors.request.use(
  (config: any) => {
    startLoading();
      //  给每个请求添加请求头
      const authStore = adminAuthStore()
      if (authStore.token) { 
        config.headers['authorization'] = authStore.token
      }     
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    endLoading()
    if (response.status == 200) {
      //从后端获取的数据
      // console.log('response',response);    
      return response.data
    } else {
      return Promise.reject(new Error('Error'))
    }
  },
  (error) => {
    endLoading()
    console.log(error.message)
    ElMessage({
      message: 'Error',
      type: 'error',
      duration: 5 * 1000
    })
    if (error.response.status === 400 || error.response.status === 401 || error.response.status === 500) {
      ElMessageBox.confirm(
        '你已被登出，可以取消继续留在该页面，或者重新登录',
        'Warning',
        {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        ResetToken()
        location.reload()
      })
    }
    return Promise.reject(error)
  }
)

export default service