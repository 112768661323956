<template>
    <el-row :gutter="20">
        <el-col :span="4" :offset="19">
            <!-- <span class="content-text">欢迎{{UserModule.roleName}}:{{UserModule.userName}}</span> -->
            <span class="content-text">{{adminAuthStore().roleName}}  :  {{adminAuthStore().adminname}}</span>
       
        </el-col>
        <el-col :span="1">
            <el-dropdown>
                <span class="el-dropdown-link">
                    <!-- <el-avatar :icon="adminAuthStore().avatar" /> -->
                    <!-- <el-image style="width:35px;height:35px" :src="adminAuthStore().avatar"></el-image> -->
                    <el-avatar :size="35" :src="adminAuthStore().avatar" />

                </span>
                <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click.prevent="updateShopFun">信息管理</el-dropdown-item>
                    <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
                </template>
            </el-dropdown>
        </el-col>
    </el-row>
    <update :updateShow="updateShow" :shopInfo="updateShopVal"  @closeUpdateShop="closeUpdateShop" @updateShopComplete="onSubmit" />

</template>

<script lang="ts" setup>
// import {UserFilled} from '@element-plus/icons-vue'
import { ref, toRefs, toRef, reactive, watch, computed, PropType, onMounted } from 'vue'
import { Logout } from '@/store/modules/admin'
import { useRouter } from 'vue-router'
import { adminAuthStore, GetAdminInfo } from '@/store/modules/admin';
import update from './update.vue'
    
const router = useRouter();
const logout = async()=>{
    console.log("logout")
    await Logout()
    router.push({path:'/login'})
}

const onSubmit = async () => {
    try {
        await GetAdminInfo()

    } catch (error) {
        console.log(error);
    }
    
}

//更改

const updateShow = ref(false)

const closeUpdateShop= (v: boolean) => {
    // console.log(v)
    updateShow.value = false;
    // onSubmit()
}

const updateShopVal = ref()
const updateShopFun = () => {
    const adminStore = adminAuthStore()
    updateShopVal.value = adminStore;
    updateShow.value = true;
}
</script>

<style scoped>
    .el-row{
        margin-top: 10px;
    }
    
</style>