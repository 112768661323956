import request from '@/utils/axios'

export const login = (data: any) =>
  request({
    url: '/admin/login',
    method: 'post',
    data
})

export const getAdminByToken = (adminnum:any) =>
  request({
    url: `/admin/adminInfo/${adminnum}`,
    method: 'get',
})